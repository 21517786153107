import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const PageHeader = loadable(() => import('/src/components/Structure/PageHeader'))

// Standard components
const Text = loadable(() => import('./layouts/standard/Text'))
const ContentWithMedia = loadable(() => import('./layouts/standard/ContentWithMedia'))
const ContentWithMediaCtaList = loadable(() => import('./layouts/standard/ContentWithMediaCtaList'))
const ImageList = loadable(() => import('./layouts/standard/ImageList'))
const CallToAction = loadable(() => import('./layouts/standard/CallToAction'))
const CalloutStrip = loadable(() => import('./layouts/standard/CalloutStrip'))
const CardList = loadable(() => import('./layouts/standard/CardList'))
const AccordionBlock = loadable(() => import('./layouts/standard/AccordionBlock'))
const Hero = loadable(() => import('./layouts/standard/Hero'))
const ImageCallout = loadable(() => import('./layouts/standard/ImageCallout'))
const StandaloneButton = loadable(() => import('./layouts/standard/StandaloneButton'))
const ItemList = loadable(() => import('./layouts/standard/ItemList'))
const ImageBullets = loadable(() => import('./layouts/standard/ImageBullets'))
const Table = loadable(() => import('./layouts/standard/Table'))
const FormBlock = loadable(() => import('./layouts/standard/Form'))
const JourneyBlock = loadable(() => import('./layouts/standard/Journey'))
const SinglePageJourneyBlock = loadable(() => import('./layouts/standard/SinglePageJourney'))
const SliderCardList = loadable(() => import('./layouts/standard/SliderCardList'))
const AppointmentPickerBlock = loadable(() => import('./layouts/standard/AppointmentPickerBlock'))
const ImageStrip = loadable(() => import('./layouts/standard/ImageStrip'))
const AwardsListing = loadable(() => import('./layouts/standard/AwardsListing'))
const ProductComparison = loadable(() => import('./layouts/standard/ProductComparison'))
const ContentWithMediaGrid = loadable(() => import('./layouts/standard/ContentWithMediaGrid'))
const CardWithMediaGrid = loadable(() => import('./layouts/standard/CardWithMediaGrid'))
const Video = loadable(() => import('./layouts/standard/Video'))
const AlternatingContent = loadable(() => import('./layouts/standard/AlternatingContent'))

// Standard components that source from other components
const CarRegHero = loadable(() => import('./layouts/standard/CarRegHero'))
const ShareCodeHeader = loadable(() => import('./layouts/standard/ShareCodeHeader'))

// Global components that source from other components
const InsurerLogoGrid = loadable(() => import('./layouts/global/InsurerLogoGrid'))
const FaqsAccordion = loadable(() => import('./layouts/global/FaqsAccordion'))
const ProcessSteps = loadable(() => import('./layouts/global/ProcessSteps'))
const Awards = loadable(() => import('./layouts/global/Awards'))
const FeaturedIn = loadable(() => import('./layouts/global/FeaturedIn'))
const Ratings = loadable(() => import('./layouts/global/Ratings'))
const Specialists = loadable(() => import('./layouts/global/Specialists'))
const InsurerLogoStrip = loadable(() => import('./layouts/global/InsurerLogoStrip'))
const Complicated = loadable(() => import('./layouts/global/Complicated'))
const Who = loadable(() => import('./layouts/global/Who'))
const JoinStrip = loadable(() => import('./layouts/global/JoinStrip'))
const ContentWithMultiMedia = loadable(() => import('./layouts/global/ContentWithMultiMedia'))
const Experts = loadable(() => import('./layouts/global/Experts'))
const Reasons = loadable(() => import('./layouts/global/Reasons'))
const Directions = loadable(() => import('./layouts/global/Directions'))
const Trusted = loadable(() => import('./layouts/global/Trusted'))

// Custom global components
const InfoGrid = loadable(() => import('./layouts/global/InfoGrid'))
const Comparison = loadable(() => import('./layouts/global/Comparison'))
const Calculator = loadable(() => import('./layouts/global/Calculator'))
const TrustpilotReviews = loadable(() => import('./layouts/global/TrustpilotReviews'))
const Quiz = loadable(() => import('./layouts/global/Quiz'))

const PageBuilder = ({ post, isFrontPage, utm }) => {
    const [options] = useContext(OptionsContext)

    const components = {
        // Standard
        PageHeader,
        Text,
        ContentWithMedia,
        ContentWithMediaCtaList,
        ImageList,
        CallToAction,
        CalloutStrip,
        CardList,
        AccordionBlock,
        Hero,
        ImageCallout,
        StandaloneButton,
        ItemList,
        ImageBullets,
        Table,
        FormBlock,
        JourneyBlock,
        SinglePageJourneyBlock,
        SliderCardList,
        AppointmentPickerBlock,
        ShareCodeHeader,
        ImageStrip,
        AwardsListing,
        ProductComparison,
        ContentWithMediaGrid,
        CardWithMediaGrid,
        CarRegHero,
        Video,
        AlternatingContent,
        // Global
        InsurerLogoGrid,
        FaqsAccordion,
        ProcessSteps,
        Awards,
        FeaturedIn,
        Ratings,
        Specialists,
        Experts,
        Reasons,
        InfoGrid,
        ContentWithMultiMedia,
        Comparison,
        Calculator,
        TrustpilotReviews,
        Quiz,
        InsurerLogoStrip,
        Complicated,
        Who,
        JoinStrip,
        Directions,
        Trusted
    }

    // Detect if snow is turned on (applied to page header and hero components)
    const addSnow = options?.effects?.addSnow

    // Look at all layouts in the PageBuilder and render
    const pageBuilder = post.pageBuilder.layouts || []

    const layouts = pageBuilder.map((layout) => {
        const typename = layout.__typename.split('_').pop()

        return {
            name: typename,
            data: layout
        }
    })

    const breadcrumb = {
        type: 'standard',
        pageParent: post.wpParent
    }

    return layouts?.map((layout, index) => {
        const Component = components[layout.name]

        const titleArea = {
            addTitleArea: layout.data.addTitleArea,
            titlePosition: layout.data.titlePosition || 'centered',
            headingLevel: layout.data.headingLevel,
            title: layout.data.title,
            description: layout.data.description
        }

        return (
            <Component
                key={index}
                breadcrumb={breadcrumb}
                pageTitle={post.title}
                titleArea={titleArea}
                {...layout.data}
                isFrontPage={isFrontPage}
                utm={utm}
                addSnow={addSnow}
            />
        )
    })
}

PageBuilder.propTypes = {
    /**
     * Post object
     */
    post: PropTypes.object.isRequired,
    /**
     * Whether the current page is the homepage or not
     */
    isFrontPage: PropTypes.bool,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

export default PageBuilder

export const query = graphql`
    fragment PageBuilderForPage on WpPage {
        pageBuilder: acfPageBuilder {
            fieldGroupName
            layouts {
                __typename

                ...PageHeaderForPageBuilder

                ...TextForPageBuilder

                ...ContentWithMediaForPageBuilder

                ...ContentWithMediaCtaListForPageBuilder

                ...ImageListForPageBuilder

                ...CallToActionForPageBuilder

                ...CalloutStripForPageBuilder

                ...CardListForPageBuilder

                ...AccordionBlockForPageBuilder

                ...HeroForPageBuilder

                ...ImageCalloutForPageBuilder

                ...StandaloneButtonForPageBuilder

                ...ItemListForPageBuilder

                ...ImageBulletsForPageBuilder

                ...TableForPageBuilder

                ...FormBlockForPageBuilder

                ...JourneyBlockForPageBuilder

                ...SinglePageJourneyBlockForPageBuilder

                ...SliderCardListForPageBuilder

                ...AppointmentPickerBlockForPageBuilder

                ...ShareCodeHeaderForPageBuilder

                ...ImageStripForPageBuilder

                ...AwardsListingForPageBuilder

                ...InsurerLogoGridForPageBuilder

                ...FaqsAccordionForPageBuilder

                ...ProcessStepsForPageBuilder

                ...AwardsForPageBuilder

                ...FeaturedInForPageBuilder

                ...RatingsForPageBuilder

                ...ExpertsForPageBuilder

                ...ReasonsForPageBuilder

                ...InfoGridForPageBuilder

                ...ContentWithMultiMediaForPageBuilder

                ...ComparisonForPageBuilder

                ...CalculatorForPageBuilder

                ...TrustpilotReviewsForPageBuilder

                ...QuizForPageBuilder

                ...SpecialistsForPageBuilder

                ...ComplicatedForPageBuilder

                ...WhoForPageBuilder

                ...JoinStripForPageBuilder

                ...ProductComparisonForPageBuilder

                ...ContentWithMediaGridForPageBuilder

                ...CardWithMediaGridForPageBuilder

                ...DirectionsForPageBuilder

                ...TrustedForPageBuilder

                ...CarRegHeroForPageBuilder

                ...VideoForPageBuilder

                ...AlternatingContentForPageBuilder
            }
        }
    }
`
